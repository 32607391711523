import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  desktopMediaQuery,
  Dialog,
  enqueueTemporaryNotificationAlert,
  OtpInput,
  PersistentNotification,
  spacing,
  Spinner,
  Text,
  useAsyncCallback,
  useTranslate,
} from '@orus.eu/pharaoh'
import { isSuccess } from '@orus.eu/result'
import { memo, useCallback, type FunctionComponent } from 'react'
import { trpcReact } from '../../../../client'

type VerifyEmailDialogProps = {
  emailToVerifyInfo: { email: string; challengeId: string; expirationMs: number }
  onClose: () => void
  onLoadUser: () => Promise<void>
  onGoBack: () => void
}

export const VerifyEmailDialog: FunctionComponent<VerifyEmailDialogProps> = memo(function VerifyEmailDialog({
  emailToVerifyInfo: { email, challengeId, expirationMs },
  onClose,
  onLoadUser,
  onGoBack,
}) {
  const translate = useTranslate()
  const verifyAndUpdateEmailMutation = trpcReact.users.verifyAndUpdateEmail.useMutation()

  const handleChallengeComplete = useAsyncCallback(
    async (challengeCode: string) => {
      const result = await verifyAndUpdateEmailMutation.mutateAsync({ challengeId: challengeId, challengeCode })
      if (isSuccess(result)) {
        enqueueTemporaryNotificationAlert(translate('update_email_success'), {
          variant: 'success',
        })
        await onLoadUser()
        onClose()
      }
    },
    [challengeId, verifyAndUpdateEmailMutation, onClose, translate, onLoadUser],
  )

  const handleChallengeChange = useCallback(() => {
    verifyAndUpdateEmailMutation.reset()
  }, [verifyAndUpdateEmailMutation])

  return (
    <Dialog title={translate('security_verification')} style="base" size="medium" onClose={onClose}>
      <div
        css={css`
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
        `}
      >
        <Text variant="body2">
          {translate('enter_code_sent_to')}{' '}
          <Text element="span" variant="body2Medium">
            {email}
          </Text>
        </Text>

        <StyledChallengeInput
          state={
            verifyAndUpdateEmailMutation.data?.type === 'failure'
              ? 'error'
              : verifyAndUpdateEmailMutation.isPending
                ? 'loading'
                : 'neutral'
          }
          autoFocus
          onComplete={handleChallengeComplete}
          onChange={handleChallengeChange}
        />
        <EventBox>
          {!verifyAndUpdateEmailMutation.data ? (
            <Caption variant="caption">
              {translate('expiration_code_after_delay', { delay: `${expirationMs / 60_000}` })}
            </Caption>
          ) : null}

          {verifyAndUpdateEmailMutation.isPending ? (
            <Spinner
              size="50"
              css={css`
                align-self: center;
              `}
            />
          ) : null}
          {verifyAndUpdateEmailMutation.data?.type === 'failure' &&
          verifyAndUpdateEmailMutation.data.problem === 'wrong-code' ? (
            <PersistentNotification variant="danger" title={translate('wrong_code')}>
              {translate('wrong_code_message')}
            </PersistentNotification>
          ) : null}

          {verifyAndUpdateEmailMutation.data?.type === 'failure' &&
          verifyAndUpdateEmailMutation.data.problem === 'expired' ? (
            <PersistentNotification
              variant="danger"
              title={translate('code_expired')}
              actionButton={{
                label: translate('resend_code'),
                onClick: onGoBack,
              }}
            >
              {translate('code_expired_message')}
            </PersistentNotification>
          ) : null}

          {verifyAndUpdateEmailMutation.data?.type === 'failure' &&
          verifyAndUpdateEmailMutation.data.problem === 'too-many-attempts' ? (
            <PersistentNotification
              variant="danger"
              title={translate('too_many_attempts')}
              actionButton={{
                label: translate('resend_code'),
                onClick: onGoBack,
              }}
            >
              {translate('too_many_attempts_message')}
            </PersistentNotification>
          ) : null}
        </EventBox>
      </div>
    </Dialog>
  )
})

const StyledChallengeInput = styled(OtpInput)`
  align-self: center;
  margin-top: ${spacing[70]};
  width: 100%;
  ${desktopMediaQuery} {
    margin-top: ${spacing[60]};
    width: max-content;
  }
`

const EventBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing[60]};
`

const Caption = styled(Text)`
  text-align: center;
`
