import { enqueueTemporaryNotificationAlert, useTranslate } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { trpcReact } from '../../../client'

type ContactUpdateData = {
  fieldValue: string
  field: 'email' | 'phone'
  onClose: () => void
  onOpenVerifyEmailDialog: (email: string, challengeId: string, expirationMs: number) => void
  onLoadUser: () => Promise<void>
}

export const useAccountInformations = (): ((data: ContactUpdateData) => Promise<void>) => {
  const updatePhoneMutation = trpcReact.users.updateMyPhone.useMutation()
  const attemptEmailUpdateMutation = trpcReact.users.attemptEmailUpdate.useMutation()
  const translate = useTranslate()

  const saveContactInfo = async (data: ContactUpdateData) => {
    const { fieldValue, field, onClose, onOpenVerifyEmailDialog, onLoadUser } = data
    if (field === 'email') {
      const result = await attemptEmailUpdateMutation.mutateAsync({ email: fieldValue })

      if (isFailure(result)) {
        switch (result.problem) {
          case 'user-already-exists':
            enqueueTemporaryNotificationAlert(translate('user_already_exists'), {
              variant: 'danger',
            })
            break
          case 'too-many-attemps':
            enqueueTemporaryNotificationAlert(translate('too_many_attempts_message'), {
              variant: 'danger',
            })
            break
          default:
            enqueueTemporaryNotificationAlert(translate('update_error'), {
              variant: 'danger',
            })
        }
        return
      }

      onClose()
      onOpenVerifyEmailDialog(fieldValue, result.output.challengeId, result.output.expirationMs)
    } else {
      const result = await updatePhoneMutation.mutateAsync({
        [field]: fieldValue,
      })

      if (isFailure(result)) {
        enqueueTemporaryNotificationAlert(translate('update_error'), {
          variant: 'danger',
        })
        return
      } else {
        enqueueTemporaryNotificationAlert(translate('update_phone_success'), {
          variant: 'success',
        })
        await onLoadUser()
        onClose()
      }
    }
  }

  return saveContactInfo
}
