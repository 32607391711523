import styled from '@emotion/styled'
import { Dialog, TextInputLabelWrapper, useAsyncCallback, useTranslate, ValidatedTextField } from '@orus.eu/pharaoh'
import { memo, useCallback, useMemo, useState, type FunctionComponent } from 'react'
import { mappers } from '../../subscription-v2/elements/string-element-mappers'

import { useAccountInformations } from '../account-informations-hook'

type EditContactInformationsDialogProps = {
  fieldInitialValue: string | undefined
  field: 'email' | 'phone'
  onClose: () => void
  onLoadUser: () => Promise<void>
  onOpenVerifyEmailDialog: (newEmail: string, challengeId: string, expirationMs: number) => void
}

export const EditContactInformationsDialog: FunctionComponent<EditContactInformationsDialogProps> = memo(
  function EditContactInformationsDialog({ fieldInitialValue, field, onClose, onLoadUser, onOpenVerifyEmailDialog }) {
    const saveContactInfo = useAccountInformations()
    const translate = useTranslate()
    const [fieldValue, setFieldValue] = useState(fieldInitialValue)

    const fieldData = useMemo(() => {
      return field === 'email'
        ? {
            title: translate('update_email'),
            label: translate('dimension_name_email'),
          }
        : {
            title: translate('update_phone'),
            label: translate('dimension_name_phone'),
          }
    }, [field, translate])

    const onChange = useCallback((newValue: string | undefined) => setFieldValue(newValue), [setFieldValue])

    const onSave = useAsyncCallback(async () => {
      if (!fieldValue) return
      await saveContactInfo({ fieldValue, field, onClose, onOpenVerifyEmailDialog, onLoadUser })
    }, [fieldValue, saveContactInfo, field, onClose, onOpenVerifyEmailDialog, onLoadUser])

    return (
      <Dialog
        title={fieldData.title}
        style="base"
        size="small"
        primaryActionLabel={translate('save_label')}
        onPrimaryAction={onSave}
        secondaryActionLabel={translate('cancel')}
        onSecondaryAction={onClose}
        primaryActionDisabled={!fieldValue || fieldValue === fieldInitialValue}
        onClose={onClose}
      >
        <ValidatedTextFieldWrapper label={fieldData.label}>
          <ValidatedTextField size="large" mapper={mappers[field]} value={fieldValue} onChange={onChange} />
        </ValidatedTextFieldWrapper>
      </Dialog>
    )
  },
)

const ValidatedTextFieldWrapper = styled(TextInputLabelWrapper)`
  width: 100%;
`
