import {
  Avatar,
  Button,
  ContentContainerAppClient,
  desktopMediaQuery,
  PageTitle,
  spacing,
  Text,
  useDialogVisibility,
  useLanguage,
  useTranslate,
  useUiContext,
} from '@orus.eu/pharaoh'
import { Row, RowContainer } from '@orus.eu/pharaoh/src/components/rows'

import styled from '@emotion/styled'
import { TechnicalError } from '@orus.eu/error'
import { memo, useState } from 'react'
import { trpcReact } from '../../../client'
import { isProductionEnvironment } from '../../../lib/env-utils'
import { openTallyPopup } from '../../../lib/tally-util'
import { EditContactInformationsDialog } from './components/EditContactInformationsDialog'
import { VerifyEmailDialog } from './components/VerifyEmailDialog'

const requestFrAccountUpdateTallyId = '3EBK5X'
const requestEsAccountUpdateTallyId = '3y0Mkg'

export default memo(function AccountPage() {
  const translate = useTranslate()
  const language = useLanguage()
  const screenType = useUiContext()
  const [user, { refetch }] = trpcReact.users.loadMyInformation.useSuspenseQuery()
  const [address] = trpcReact.contracts.getLastCustomerAddress.useSuspenseQuery()

  const [fieldToEdit, setFieldToEdit] = useState<{
    name: 'phone' | 'email' | null
    initialValue: string | undefined | null
  }>({
    name: null,
    initialValue: undefined,
  })
  const [emailToVerifyInfo, setEmailToVerifyInfo] = useState<{
    email: string
    challengeId: string
    expirationMs: number
  } | null>(null)

  const {
    show: showEditContactInformationsDialog,
    hide: hideEditContactInformationsDialog,
    visible: isEditContactInformationsDialogVisible,
  } = useDialogVisibility('edit-contact-informations')

  const {
    show: showVerifyEmailDialog,
    hide: hideVerifyEmailDialog,
    visible: isVerifyEmailDialogVisible,
  } = useDialogVisibility('verify-email')

  const personalAddress = address
    ? `${address.street}, ${address.postCode} ${address.city.charAt(0).toUpperCase() + address.city.slice(1)}`
    : undefined

  const onLoadUser = async () => {
    await refetch()
  }

  const onOpenVerifyEmailDialog = (newEmail: string, challengeId: string, expirationMs: number) => {
    setEmailToVerifyInfo({ email: newEmail, challengeId, expirationMs })
    showVerifyEmailDialog()
  }

  const onEditField = (field: 'phone' | 'email') => {
    const initialEmailValue = 'email' in user ? user.email : user.unverifiedEmail
    setFieldToEdit({ name: field, initialValue: field === 'email' ? initialEmailValue : user.phone })
    showEditContactInformationsDialog()
  }

  const onGoBackToEditContactInformationsDialog = () => {
    hideVerifyEmailDialog()
    setFieldToEdit({ name: 'email', initialValue: emailToVerifyInfo?.email })
    showEditContactInformationsDialog()
  }

  const onRequestAccountUpdate = () => {
    let formId = undefined
    switch (language) {
      case 'es':
        formId = requestEsAccountUpdateTallyId
        break
      case 'fr':
        formId = requestFrAccountUpdateTallyId
        break
    }

    if (!formId) {
      throw new TechnicalError('FormId is required to open the Tally popup')
    }

    openTallyPopup(formId, {
      layout: 'modal',
      hiddenFields: {
        isProductionEnvironment: isProductionEnvironment(),
        userId: user.id,
        emailAddress: encodeURIComponent('email' in user ? user.email : user.unverifiedEmail),
        lastName: user.lastName,
        firstName: user.firstName,
        personalAddress: encodeURIComponent(personalAddress ?? ''),
      },
    })
  }

  return (
    <>
      {isEditContactInformationsDialogVisible && fieldToEdit.name && fieldToEdit.initialValue ? (
        <EditContactInformationsDialog
          field={fieldToEdit.name}
          fieldInitialValue={fieldToEdit.initialValue}
          onClose={hideEditContactInformationsDialog}
          onLoadUser={onLoadUser}
          onOpenVerifyEmailDialog={onOpenVerifyEmailDialog}
        />
      ) : null}
      {isVerifyEmailDialogVisible && emailToVerifyInfo ? (
        <VerifyEmailDialog
          emailToVerifyInfo={emailToVerifyInfo}
          onClose={hideVerifyEmailDialog}
          onLoadUser={onLoadUser}
          onGoBack={onGoBackToEditContactInformationsDialog}
        />
      ) : null}
      <PageTitle title={translate('my_account')} />
      <ContentContainerAppClient>
        <StyledContainer>
          <StyledInfoContainer>
            <StyledInfoTitleContainer>
              <Text variant="body2Medium">{translate('your_account_informations')}</Text>
              <Button
                variant="secondary"
                size={screenType === 'mobile' ? 'large' : 'small'}
                aria-label={'request-account-update'}
                onClick={onRequestAccountUpdate}
              >
                {translate('request_update')}
              </Button>
            </StyledInfoTitleContainer>
            <RowContainer variant="border" size="small">
              <Row
                variant="descriptive"
                key={'dimension_name_first_name'}
                title={translate('dimension_name_first_name')}
                titleVariant="body2Medium"
                subtitle={user.firstName}
              />
              <Row
                variant="descriptive"
                key={'dimension_name_last_name'}
                title={translate('dimension_name_last_name')}
                titleVariant="body2Medium"
                subtitle={user.lastName}
              />
              <Row
                variant="descriptive"
                key={'personal_address'}
                title={translate('personal_address')}
                titleVariant="body2Medium"
                subtitle={personalAddress ?? translate('no_customer_address')}
              />
            </RowContainer>
          </StyledInfoContainer>
          <StyledContactContainer>
            <Text variant="body2Medium">{translate('your_contact_informations')}</Text>
            <StyledRowContainer variant="border" size="small">
              <Row
                variant="descriptive"
                key={'dimension_name_email'}
                title={translate('dimension_name_email')}
                titleVariant="body2Medium"
                subtitle={'email' in user ? user.email : user.unverifiedEmail}
                button={
                  <Button
                    variant={screenType === 'mobile' ? 'secondary' : 'text'}
                    size="small"
                    avatarPosition="left"
                    aria-label={'update-email'}
                    onClick={() => onEditField('email')}
                    avatar={<Avatar icon="pen-regular" />}
                  >
                    {screenType !== 'mobile' ? translate('modify') : ''}
                  </Button>
                }
              />
              <Row
                variant="descriptive"
                key={'dimension_name_phone'}
                title={translate('dimension_name_phone')}
                titleVariant="body2Medium"
                subtitle={user.phone}
                button={
                  <Button
                    variant={screenType === 'mobile' ? 'secondary' : 'text'}
                    size="small"
                    avatarPosition="left"
                    aria-label={'update-phone'}
                    onClick={() => onEditField('phone')}
                    avatar={<Avatar icon="pen-regular" />}
                  >
                    {screenType !== 'mobile' ? translate('modify') : ''}
                  </Button>
                }
              />
            </StyledRowContainer>
          </StyledContactContainer>
        </StyledContainer>
      </ContentContainerAppClient>
    </>
  )
})

const StyledInfoTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[30]};

  ${desktopMediaQuery} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[50]};
`

const StyledContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[50]};
`

const StyledContainer = styled.div`
  padding: ${spacing[60]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[70]};

  ${desktopMediaQuery} {
    padding: 0;
  }
`
const StyledRowContainer = styled(RowContainer)`
  word-break: break-all;
`
